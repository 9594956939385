import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import ListaProduto from "./../views/Produto/ListaProduto.vue";
import CadastroProduto from "./../views/Produto/CadastroProduto.vue";
import CadastroLoja from "./../views/Loja/CadastroLoja.vue";
import ListaLoja from "./../views/Loja/ListaLoja.vue";

import CadastroCliente from "./../views/Cliente/CadastroCliente.vue";
import ListaCliente from "./../views/Cliente/ListaCliente.vue";

import CadastroFornecedor from "./../views/Fornecedor/CadastroFornecedor.vue";
import ListaFornecedor from "./../views/Fornecedor/ListaFornecedor.vue";

import CadastroUsuario from "./../views/Usuario/WEB/CadastroUsuario.vue";
import ListaUsuario from "./../views/Usuario/WEB/ListaUsuario.vue";

import CadastroFuncionario from "./../views/Usuario/Funcionario/CadastroFuncionario.vue";
import ListaFuncionario from "./../views/Usuario/Funcionario/ListaFuncionario.vue";

import CadastroSecao from "./../views/Secao/SecaoCadastroNovo.vue";

import CadastroTributacao from "../views/Fiscal/Tributacao/CadastroTributacao.vue";
import ListaTributacao from "../views/Fiscal/Tributacao/ListarTributacao.vue";

import ListaImpFederal from "@/views/Fiscal/Federais/ListaImpFederais.vue";
import CadastroImpFderal from "@/views/Fiscal/Federais/CadastroImpFederal.vue";

import CargaLoja from "@/views/Carga/Loja.vue";

import Downloads from "@/views/Downloads/Downloads.vue";

import ListaFinalizadora from "@/views/Finalizadora/ListaFinalizadora.vue";
import CadastroFinalizadora from "@/views/Finalizadora/CadastroFinalizadora.vue";

import PainelVendas from "@/views/Relatorio/Caixa/Painel/Painel.vue";
import PainelControle from "@/views/Relatorio/Caixa/Controle/Painel.vue";

import PainelEstoque from "@/views/Relatorio/Estoque/Painel.vue";

import Login from "@/views/Deslogado/Login.vue";

import RegistrarAdmin from "@/views/Deslogado/PainelAdmin/RegistrarAdmin.vue";
import ClientesAdmin from "@/views/Deslogado/PainelAdmin/ListarClientes.vue";
import LoginAdmin from "@/views/Deslogado/LoginAdmin.vue";

import Configuracoes from "@/views/Configuracoes/Configuracoes.vue";

import ContasAPagar from "@/views/Financeiro/ContasAPagar/ContasAPagar.vue";

import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/administracao",
    name: "admin",
    components: {
      "rotas-deslogado": LoginAdmin,
    },
  },
  {
    path: "/administracao/registrar",
    name: "admin",
    components: {
      "rotas-deslogado": RegistrarAdmin,
    },
    meta: {
      requiresAuthAdmin: true,
    },
  },
  {
    path: "/administracao/clientes",
    name: "admin",
    components: {
      "rotas-deslogado": ClientesAdmin,
    },
    meta: {
      requiresAuthAdmin: true,
    },
  },

  {
    path: "/",
    name: "home",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastro/produto",
    name: "Produto",
    component: ListaProduto,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastro/produto/:codigo",
    name: "Produto",
    component: CadastroProduto,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastro/loja",
    name: "Loja",
    component: ListaLoja,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastro/loja/:codigo",
    name: "Loja",
    component: CadastroLoja,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastro/cliente/:codigo",
    name: "Cliente",
    component: CadastroCliente,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastro/cliente",
    name: "Cliente",
    component: ListaCliente,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/usuarios/usuariosweb",
    name: "Usuários WEB",
    component: ListaUsuario,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/usuarios/funcionario",
    name: "Funcionário",
    component: ListaFuncionario,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/usuarios/funcionario/:codigo",
    name: "Funcionário",
    component: CadastroFuncionario,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/usuarios/usuariosweb/:codigo",
    name: "Usuários WEB",
    component: CadastroUsuario,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastro/fornecedor/:codigo",
    name: "Fornecedor",
    component: CadastroFornecedor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastro/fornecedor",
    name: "Fornecedor",
    component: ListaFornecedor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastro/secoes",
    name: "Loja",
    component: CadastroSecao,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fiscal/tributacao",
    name: "Tributação",
    component: ListaTributacao,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fiscal/tributacao/:codigo",
    name: "Tributação",
    component: CadastroTributacao,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fiscal/impfederal",
    name: "Impostos Federais",
    component: ListaImpFederal,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fiscal/impfederal/:codigo",
    name: "Impostos Federais",
    component: CadastroImpFderal,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/financeiro/contas-a-pagar",
    name: "Contas A Pagar",
    component: ContasAPagar,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/carga/loja",
    name: "Carga - Loja",
    component: CargaLoja,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/login",
    name: "Login",
    components: {
      "rotas-deslogado": Login,
    },
  },
  {
    path: "/downloads",
    name: "Downloads",
    component: Downloads,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastro/finalizadora",
    name: "Finalizadora",
    component: ListaFinalizadora,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastro/finalizadora/:codigo",
    name: "Finalizadora",
    component: CadastroFinalizadora,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/relatorio/caixa/painel",
    name: "Relatório - Painel de Vendas",
    component: PainelVendas,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/relatorio/caixa/controle",
    name: "Relatório - Controle Caixa",
    component: PainelControle,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/relatorio/estoque/painel",
    name: "Relatório - Painel de Estoque",
    component: PainelEstoque,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes",
    name: "Configurações do Sistema",
    component: Configuracoes,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const isTokenExists = localStorage.getItem("access_token");
  const isTokenAdminExists = localStorage.getItem("access_token_admin");
  Vue.prototype.$http.defaults.headers.common["x-access-token"] = isTokenExists;
  Vue.prototype.$http.defaults.headers.common["x-access-token-admin"] =
    isTokenAdminExists;

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAuthAdmin = to.matched.some(
    (record) => record.meta.requiresAuthAdmin
  );

  if (requiresAuthAdmin && !isTokenAdminExists) {
    next("/login");
  }

  if (requiresAuth && !isTokenExists) {
    next("/login");
  } else if (isTokenExists) {
    store.commit("setLogado", true);
    next();
  } else {
    store.commit("setLogado", false);
    next();
  }
});

export default router;
