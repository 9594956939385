<template>
  <div>
    <v-card>
      <v-card-title>Contas A Pagar - Incluir</v-card-title>
      <v-card-text
        ><v-row>
          <v-col cols="12" sm="5">
            <label for="input-example">Loja:</label>
            <v-autocomplete
              type="text"
              :item-value="(item) => item"
              :items="loja_list"
              :item-text="(item) => `${item.id} - ${item.nome}`"
              placeholder="Selecione a Loja"
              id="loja"
            />
          </v-col>
          <v-col cols="12" sm="5">
            <label for="input-example">Fornecedor:</label>
            <v-autocomplete
              type="text"
              :item-value="(item) => item"
              :items="fornecedor_list"
              :item-text="(item) => `${item.id} - ${item.nome}`"
              placeholder="Selecione o Fornecedor"
              id="fornecedor"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <label for="">Número Documento</label>
            <v-text-field
              v-model="conta_pagar.numeroDocumento"
              @keyup="handleInput()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <label for="">Data Emissão</label>
            <v-text-field
              type="date"
              v-model="conta_pagar.dataEmissao"
              @keyup="handleInput()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <label for="">Data Vencimento</label>
            <v-text-field
              type="date"
              v-model="conta_pagar.dataVencimento"
              @keyup="handleInput()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <label for="">Data Competência</label>
            <v-text-field
              type="date"
              v-model="conta_pagar.dataCompetencia"
              @keyup="handleInput()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="2">
            <label for="">Valor Nominal</label>
            <v-text-field
              v-model="conta_pagar.valor_nominal"
              @keyup="handleInput()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <label for="">Valor Desconto</label>
            <v-text-field
              @keyup="handleInput()"
              v-model="conta_pagar.valor_desconto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <label for="">Valor Acréscimo</label>
            <v-text-field
              @keyup="handleInput()"
              v-model="conta_pagar.valor_acrescimo"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <label for="">Valor Líquido</label>
            <v-text-field
              :readonly="true"
              @keyup="handleInput()"
              :value="conta_pagar.valor_liquido().money"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ContaPagar from "@/infra/entity/ContaPagar";
import LojaController from "@/infra/controller/LojaController";
import FornecedorController from "@/infra/controller/FornecedorController";
export default {
  async mounted() {
    await this.carregarLojas();
    await this.carregarFornecedores();
  },

  data() {
    return {
      loja_list: [],
      fornecedor_list: [],
      conta_pagar: new ContaPagar({}),
    };
  },
  methods: {
    handleInput() {
      this.conta_pagar = new ContaPagar(this.conta_pagar);
    },
    async carregarFornecedores() {
      const res = await FornecedorController.getAll();
      this.fornecedor_list = res.data;
    },
    async carregarLojas() {
      const res = await LojaController.getAll();
      this.loja_list = res.data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
