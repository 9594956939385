import Vue from "vue";
import Vuex from "vuex";
import Application from "./modules/application";
import produto from "./modules/produto";
import loja from "./modules/loja";
import preco from "./modules/preco";
import secao from "./modules/secao";
import grupo from "./modules/grupo";
import cliente from "./modules/cliente";
import fornecedor from "./modules/fornecedor";
import usuario from "./modules/usuario";
import error from "./modules/error";
import tributacao from "./modules/tributacao";
import funcionario from "./modules/funcionario";
import tenant from "./modules/tenant";
import finalizadora from "./modules/finalizadora";
import estoque from "./modules/estoque";
import impfederal from "./modules/impfederal";
import relatorio from "./modules/relatorio";
import admin from "./modules/admin";
import fechamento from "./modules/fechamento";
import naofiscal from "./modules/naofiscal";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Application,
    produto,
    loja,
    preco,
    secao,
    grupo,
    cliente,
    fornecedor,
    error,
    tributacao,
    usuario,
    funcionario,
    tenant,
    finalizadora,
    estoque,
    impfederal,
    relatorio,
    admin,
    fechamento,
    naofiscal,
  },
});
