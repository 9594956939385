import { AxiosInstance, AxiosResponse } from "axios";
import Vue from "vue";

class Connection {
  // Método GET
  async get<T>(endpoint: string): Promise<AxiosResponse<T>> {
    try {
      return await Vue.prototype.$http.get(endpoint);
    } catch (error) {
      this.handleError(error);
      throw error; // Re-throw error after handling
    }
  }

  // Método POST
  async post<T>(endpoint: string, data: T): Promise<AxiosResponse<T>> {
    try {
      return await Vue.prototype.$http.post(endpoint, data);
    } catch (error) {
      this.handleError(error);
      throw error; // Re-throw error after handling
    }
  }

  // Método PUT
  async put<T>(endpoint: string, data: T): Promise<AxiosResponse<T>> {
    try {
      return await Vue.prototype.$http.put(endpoint, data);
    } catch (error) {
      this.handleError(error);
      throw error; // Re-throw error after handling
    }
  }

  // Método DELETE
  async delete<T>(endpoint: string): Promise<AxiosResponse<T>> {
    try {
      return await Vue.prototype.$http.delete(endpoint);
    } catch (error) {
      this.handleError(error);
      throw error; // Re-throw error after handling
    }
  }

  // Método para tratar erros
  private handleError(error: any): void {
    // Aqui você pode implementar lógica para tratar erros globalmente
    console.error("API Error:", error);
  }
}

// Exporta uma instância da classe Connection com a URL base
export default new Connection();
