import Response from "../entity/Response";
import LojaRepository from "../repository/LojaRepository";

class LojaController {
  async getAll() {
    const res = await LojaRepository.getAll();
    return new Response(res.status, "", res.data);
  }
}
export default new LojaController();
