import Preco from "./value_object/Preco";

export default class ContaPagar {
  lojaId: number;
  fornecedorId: number;
  numeroDocumento: string;
  dataVencimento: Date;
  dataEmissao: Date;
  dataCompetencia: Date;
  _valor_nominal: any;
  valor_desconto: number;
  valor_acrescimo: number;
  status: "Pendente" | "Pago" | "Atrasado";

  constructor(input: {
    lojaId: number;
    fornecedorId: number;
    numeroDocumento: string;
    dataVencimento?: Date; // Optional
    dataEmissao?: Date; // Optional
    dataCompetencia?: Date; // Optional
    valor_nominal: any;
    valor_desconto: number;
    valor_acrescimo: number;
    status?: "Pendente" | "Pago" | "Atrasado"; // Optional
  }) {
    const {
      lojaId = 0,
      fornecedorId = 0,
      numeroDocumento = "",
      dataVencimento = new Date(),
      dataEmissao = new Date(),
      dataCompetencia = new Date(),
      valor_nominal = 0,
      valor_desconto = 0,
      valor_acrescimo = 0,
      status = "Pendente",
    } = input;

    this.lojaId = lojaId;
    this.fornecedorId = fornecedorId;
    this.numeroDocumento = numeroDocumento.toUpperCase();
    this.dataVencimento = dataVencimento;
    this.dataEmissao = dataEmissao;
    this.dataCompetencia = dataCompetencia;
    this._valor_nominal = Preco.create(valor_nominal);
    this.valor_desconto = Number(valor_desconto);
    this.valor_acrescimo = Number(valor_acrescimo);
    this.status = status;
  }
  get valor_nominal() {
    return this._valor_nominal.money;
  }
  set valor_nominal(value: any) {
    this._valor_nominal = Preco.create(value);
  }

  valor_liquido(): any {
    return Preco.create(
      Number(this._valor_nominal.value) -
        Number(this.valor_desconto) +
        Number(this.valor_acrescimo)
    );
  }
}
