<template>
  <v-app>
    <v-container>
      <!-- Botão para exibir/ocultar os campos de filtro -->
      <v-row>
        <v-col cols="12">
          <v-btn color="primary" @click="toggleFilters">{{
            showFilters ? "Ocultar Filtros" : "Exibir Filtros"
          }}</v-btn>
        </v-col>
      </v-row>

      <!-- Campos de filtro -->
      <v-row v-if="showFilters">
        <v-col cols="2">
          <v-text-field
            v-model="filterValues.numero"
            label="Número do Cupom"
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            label="Loja"
            item-text="nome"
            item-value="codigo"
            :items="lojaList"
            dense
            v-model="filterValues.loja"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-text-field
            dense
            v-model="filterValues.caixa"
            label="Caixa"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            dense
            v-model="filterValues.valor_total"
            label="Valor Total"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            label="Cliente"
            item-text="nome"
            item-value="codigo"
            :items="clienteList"
            dense
            v-model="filterValues.codigo_cliente"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-checkbox
            true-value="1"
            false-value="0"
            label="Cancelado"
            class="mt-0"
            v-model="filterValues.cancelado"
            dense
          ></v-checkbox>
          <!-- <v-checkbox
            true-value="1"
            false-value="0"
            label="Efetivado"
            class="mt-0"
            v-model="filterValues.efetivado"
            dense
          ></v-checkbox> -->
        </v-col>
        <v-col cols="4">
          <v-text-field
            dense
            v-model="filterValues.cpf_consumidor"
            label="CPF Consumidor"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            label="Vendedor"
            item-text="nome"
            item-value="codigo"
            :items="funcionarioList"
            dense
            v-model="filterValues.vendedor"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-text-field
            dense
            v-model="filterValues.xml_venda"
            label="XML Venda"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            dense
            v-model="filterValues.xml_cancelamento"
            label="XML Cancelamento"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" @click="applyFilter">Filtrar</v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="filteredPrecosMascarados"
        :items-per-page="10"
        class="elevation-1"
        style="margin-top: 20px"
      >
        <template v-slot:item="{ item }">
          <tr @click="carregarCupom(item)">
            <td>{{ item.numero }}</td>
            <td>{{ item.data }}</td>
            <td>{{ item.hora }}</td>
            <td>{{ item.caixa }}</td>
            <td>{{ item.valor_total }}</td>
            <td>{{ item.cpf_consumidor }}</td>
            <td>{{ item.qtde_item }}</td>
            <td>{{ item.xml_venda }}</td>
            <td>{{ item.xml_cancelamento }}</td>
          </tr>
        </template>
        <template slot="body.append">
          <tr class="blue--text">
            <th class="title">Totais</th>
            <th class="title"></th>
            <th class="title"></th>
            <th class="title"></th>
            <th class="title">{{ sumField("valor_total") }}</th>
            <th class="title"></th>
            <th class="title">{{ sumField("qtde_item") }}</th>
          </tr>
        </template>
      </v-data-table>

      <v-divider></v-divider>
      <h5>Itens do Cupom: {{ selectedCupom.numero }}</h5>

      <v-data-table
        :headers="headersItens"
        :items="itensCuponsMask"
        :items-per-page="10"
        class="elevation-1"
        style="margin-top: 20px"
      />
    </v-container>
  </v-app>
</template>

<script>
import { maskMoney, maskMoneyToFloat, maskQtd } from "@/utils/masks";

export default {
  async mounted() {
    await this.$store.dispatch("getLojas");
    await this.$store.dispatch("getClientes");
    await this.$store.dispatch("getFuncionarios");
  },
  data() {
    return {
      headersItens: [
        { text: "Seq", value: "item" },
        { text: "Cód. Prod.", value: "codigo_barras" },
        { text: "Nome Prod.", value: "descricao" },
        { text: "UN", value: "unidade" },
        { text: "Qtde.", value: "qtde" },
        { text: "Vlr. Unitário", value: "valor_unitario" },
        { text: "Vlr. Desconto", value: "valor_desconto" },
        { text: "Vlr. Acréscimo", value: "valor_acrescimo" },
        { text: "Vlr. Total", value: "valor_total" },
        { text: "Cancelado", value: "cancelado" },
      ],
      headers: [
        { text: "Número", value: "numero" },
        { text: "Data", value: "data" },
        { text: "Hora", value: "hora" },
        { text: "Caixa", value: "caixa" },
        { text: "Valor Total", value: "valor_total" },
        { text: "CPF Consumidor", value: "cpf_consumidor" },
        { text: "Qtd. Item", value: "qtde_item" },
        { text: "XML Venda", value: "xml_venda" },
        { text: "XML Cancelamento", value: "xml_cancelamento" },
      ],
      filterValues: {
        numero: "",
        loja: "",
        hora: "",
        caixa: "",
        valor_total: "",
        codigo_cliente: "",
        cancelado: "0",
        cpf_consumidor: "",
        vendedor: "",
        xml_venda: "",
        xml_cancelamento: "",
      },
      filteredPrecosMascarados: [],
      selectedCupom: {},
      showFilters: false,
    };
  },
  watch: {
    relatorio() {
      this.applyFilter();
    },
  },
  computed: {
    itensCupomList: {
      get() {
        return this.$store.state.relatorio.relatorioCupomUnico;
      },
      set(valor) {
        this.selectedCupom = {};
        this.$store.commit("setRelatorioCupomUnico", valor);
      },
    },
    precosMascarados() {
      return this.relatorio.map((item) => {
        return {
          ...item,
          valor_total: maskMoney(item.valor_total),
          qtde_item: maskQtd(item.qtde_item),
        };
      });
    },

    itensCuponsMask() {
      return this.itensCupomList.map((item) => {
        return {
          ...item,
          valor_total: maskMoney(item.valor_total),
          valor_acrescimo: maskMoney(item.valor_acrescimo),
          valor_desconto: maskMoney(item.valor_desconto),
          valor_unitario: maskMoney(item.valor_unitario),
          qtde: maskQtd(item.qtde),
          cancelado: item.cancelado === 1 ? "CANCELADO" : "",
        };
      });
    },
    relatorio() {
      return this.$store.state.relatorio.relatorioPainelVendasCupom;
    },
    lojaList() {
      return this.$store.state.loja.lojaList;
    },
    clienteList() {
      return this.$store.state.cliente.clienteList;
    },
    funcionarioList() {
      return this.$store.state.funcionario.funcionarioList;
    },
  },
  methods: {
    async carregarCupom(cupom) {
      this.selectedCupom = cupom;
      this.$store.commit("setContainerLoading", true);
      await this.$store.dispatch("getCupomUnico", cupom);
      this.$store.commit("setContainerLoading", false);
    },
    sumField(field) {
      let total = 0;
      if (this.filteredPrecosMascarados) {
        total = this.filteredPrecosMascarados.reduce(
          (accumulator, item) => accumulator + maskMoneyToFloat(item[field]),
          0
        );
      }
      return maskQtd(total);
    },
    applyFilter() {
      this.itensCupomList = [];
      console.log("123");
      this.filteredPrecosMascarados = this.precosMascarados.filter((item) => {
        for (var key in this.filterValues) {
          if (
            this.filterValues[key] !== "" &&
            this.filterValues[key] !== null &&
            this.filterValues[key] !== undefined &&
            (item === null ||
              item === undefined ||
              item[key] === null ||
              item[key] === undefined ||
              this.filterValues[key] === null ||
              this.filterValues[key] === undefined ||
              item[key]
                .toString()
                .toLowerCase()
                .indexOf(this.filterValues[key].toString().toLowerCase()) ===
                -1)
          ) {
            return false;
          }
        }
        return true;
      });
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
  },
};
</script>

<style>
.v-data-table {
  cursor: pointer;
}
</style>
