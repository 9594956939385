<template>
  <v-app>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="precosMascarados"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.barras_produto }}</td>
            <td>{{ item.nome_produto }}</td>
            <td>{{ item.qtde }}</td>
            <td>{{ item.custo_total }}</td>
            <td>{{ item.venda_total }}</td>
            <td>{{ item.venda_liquida }}</td>
            <td>{{ item.markup }}</td>
          </tr>
        </template>
        <template slot="body.append">
          <tr class="blue--text">
            <th class="title">Totais</th>
            <th class="title"></th>
            <th class="title">{{ sumField("qtde") }}</th>
            <th class="title">{{ sumField("custo_total") }}</th>
            <th class="title">{{ sumField("venda_total") }}</th>
            <th class="title">{{ sumField("venda_liquida") }}</th>
          </tr>
        </template>
      </v-data-table>
    </v-container>
  </v-app>
</template>

<script>
import { maskAmount, maskMoney, maskQtd } from "@/utils/masks";

export default {
  data() {
    return {
      headers: [
        { text: "Cód Produto", value: "barras_produto" },
        { text: "Produto", value: "nome_produto" },
        { text: "Qtde", value: "qtde" },
        { text: "Custo Total", value: "custo_total" },
        { text: "Venda Total", value: "venda_total" },
        { text: "Venda Líquida", value: "venda_liquida" },
      ],
    };
  },
  computed: {
    precosMascarados() {
      return this.relatorio.map((venda) => {
        return {
          ...venda,
          venda_total: maskMoney(venda.venda_total),
          custo_total: maskMoney(venda.custo_total),
          venda_liquida: maskMoney(venda.venda_liquida),
          qtde: maskQtd(venda.qtde),
          markup: maskAmount(venda.markup),
        };
      });
    },
    relatorio() {
      return this.$store.state.relatorio.relatorioPainelVendasProdutos;
    },
  },
  methods: {
    sumField(field) {
      let total = 0;
      if (this.relatorio) {
        total = this.relatorio.reduce(
          (accumulator, item) => accumulator + Number(item[field]),
          0
        );
      }
      return maskQtd(total);
    },
  },
};
</script>
