<template>
  <v-row>
    <v-col cols="12" sm="6">
      <v-card>
        <v-card-title class="headline">Integrador com o RK Nuvem</v-card-title>
        <v-card-text>Integrador com o RK Nuvem </v-card-text>
        <v-card-actions>
          <v-btn
            href="https://drive.google.com/file/d/1Zgf-oe4KE-kEd8Nfd6sOd2FCg5IQzUGm/view?usp=sharing"
            target="_blank"
            color="primary"
            text
            >Download</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6">
      <v-card>
        <v-card-title class="headline">Sistema RK Solutions</v-card-title>
        <v-card-text>Sistema RK Solutions </v-card-text>
        <v-card-actions>
          <v-btn
            href="https://drive.google.com/file/d/1q3cNxyf6-PVgYcsrpmMqkgtVViOGHrRh/view?usp=drive_link"
            target="_blank"
            color="primary"
            text
            >Download</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
