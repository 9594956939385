import Response from "../entity/Response";
import FornecedorRepository from "../repository/FornecedorRepository";

class FornecedorController {
  async getAll() {
    const res = await FornecedorRepository.getAll();
    return new Response(res.status, "", res.data);
  }
}
export default new FornecedorController();
